import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames";

export default class VimeoChat extends React.Component {
  render() {
    let cleanId = (this.props.vimeoId && this.props.vimeoId.indexOf("/") > 0) ? this.props.vimeoId.substring(0, this.props.vimeoId.indexOf("/")) : this.props.vimeoId;
    return (
      <div className={classNames(this.props.className, "responsiveChat")}>
        {cleanId &&
          <iframe title={this.props.title ? this.props.title : "chat-" + cleanId} src={"https://vimeo.com/live-chat/" + cleanId} frameBorder="0"></iframe>
        }
      </div>
    )
  }
}

VimeoChat.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  vimeoId: PropTypes.string
}
