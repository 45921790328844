import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import Section from "../../components/Section";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headline from "../../components/Headline";
import VimeoEmbed from "../../components/VimeoEmbed";
import VimeoChat from "../../components/VimeoChat";
import { dateTimeToStr, getWLSUser, setWLSUser, sortByName } from "../../services/utility";

export default class WLS2021 extends React.Component {
  constructor(props) {
    super(props)
    let wlsUser = getWLSUser();
    this.state = {
      featuredItem: { "name": "none" },
      isChatLive: false,
      currentSessions: [],
      futureSessions: [],
      pastSessions: [],
      firstName: wlsUser.firstName,
      lastName: wlsUser.lastName,
      isRegistered: (wlsUser.email ? true : false)
    }
    this.clearRegistered = this.clearRegistered.bind(this);
  }

  componentDidMount() {
    this.checkEventLive(this.props.data.ministryEvent);
    this.timer = setInterval(() => {
      this.checkEventLive(this.props.data.ministryEvent);  
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  checkEventLive(ministryEvent) {
    if(!ministryEvent) { return };
    var featuredItem = ministryEvent;
    var isChatLive = false;
    let nowStr = dateTimeToStr(new Date());
    let allSessions = ministryEvent.sessions ? ministryEvent.sessions.sort(sortByName) : [];

    let currentSessions = allSessions.filter(function(item) {
      let sys = item ? item.sys : undefined;
      let contentType = (sys && sys.contentType && sys.contentType.sys) ? sys.contentType.sys.id : undefined;
      let isSessionWithStart = (contentType && contentType === 'ministryEventSession' && typeof item.startDate !== 'undefined');
      return (isSessionWithStart 
              && (dateTimeToStr(item.startDate, 0-(1*60*60*1000)) <= nowStr) 
              && (dateTimeToStr(item.startDate, (6*60*60*1000)) > nowStr)); 
    }); // Starts within 1 hour and 6 hours ago

    let futureSessions = allSessions.filter(function(item) { return ((typeof item.startDate !== 'undefined' && dateTimeToStr(item.startDate, 0-(1*60*60*1000)) > nowStr) && typeof item.vimeoId !== 'undefined') }); // Starts more than 1 hour from now
    let pastSessions = allSessions.filter(function(item) { return ((typeof item.startDate === 'undefined' || dateTimeToStr(item.startDate, (6*60*60*1000)) <= nowStr) && typeof item.vimeoId !== 'undefined') }); // No start or started more than 4 hours ago

    if(currentSessions && currentSessions.length > 0) {
      featuredItem = currentSessions[currentSessions.length - 1];
      if(typeof featuredItem.vimeoId !== 'undefined' && typeof featuredItem.startChatWindow !== 'undefined' && typeof featuredItem.closeChatWindow !== 'undefined') {
        isChatLive = (dateTimeToStr(featuredItem.startChatWindow) <= nowStr && dateTimeToStr(featuredItem.closeChatWindow) > nowStr);
      }
    }

    if(featuredItem.name !== this.state.featuredItem.name || isChatLive !== this.state.isChatLive || futureSessions.length !== this.state.futureSessions.length || pastSessions.length !== this.state.pastSessions.length) {
      this.setState({
        featuredItem: featuredItem,
        isChatLive: isChatLive,
        currentSessions: currentSessions,
        futureSessions: futureSessions,
        pastSessions: pastSessions
      })
    }
  }

  clearRegistered(event) {
    if(event) {
      event.preventDefault();
    }
    this.setState({ isRegistered: false });
    setWLSUser(undefined);
    window.location.href = '/events/wls-2021-reg';
  }

  render() {
    let bestName = (typeof this.state.featuredItem.title !== 'undefined' ? this.state.featuredItem.title : this.state.featuredItem.name);
    return ( 
      <Layout transparentNav={true} darkNav={true} className="wls2021">
        <Seo title="Stronger - WLS 2021" />
        <Section className="wls2021TopSection dark" useRow={false}>
          <Row className="align-items-center justify-content-center">
            <Col xs={12} lg={this.state.isChatLive ? 8 : 7} xl={this.state.isChatLive ? 8 : 6}>
              {this.state.isChatLive && <Headline className="pt-3">{bestName}</Headline> }
              { this.state.featuredItem.vimeoId && <VimeoEmbed vimeoId={this.state.featuredItem.vimeoId} useCinema={this.state.featuredItem.cinematicAspectRatio} /> }
              { !this.state.featuredItem.vimeoId && this.state.featuredItem.image && <GatsbyImage image={getImage(this.state.featuredItem.image)} alt={bestName} /> }
            </Col>
            <Col className="py-3" xs={12} lg={this.state.isChatLive ? 4 : 5} xl={this.state.isChatLive ? 4 : 6}>
              {!this.state.isChatLive && <Headline className="pt-3">{bestName}</Headline> }
              {!this.state.isChatLive && this.state.featuredItem.description && <div dangerouslySetInnerHTML={{
                  __html: this.state.featuredItem.description.childMarkdownRemark.html,
                }}
              />}
              {this.state.isChatLive && <h4>Chat <span>(available during event)</span></h4> }
              {this.state.isChatLive && <VimeoChat vimeoId={this.state.featuredItem.vimeoId}></VimeoChat>}
            </Col>
          </Row>
          { ((this.state.currentSessions.length > 0) || (this.state.pastSessions.length > 0)) &&
          <Row>
            <Col xs={12}>
              <div className="text-center pt-4 gospel-link"><Link className="body-link" to="/events/wls-2021-hope">Click here if you just chose to follow Jesus</Link></div>              
            </Col>
          </Row> }
        </Section>
        <Section className="wls2021SessionSection dark" useContainer={false}>
          <Container id="sessions">
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline className="white" center={true}>WLS Sessions<span id="wls-sessions"> </span></Headline>
                {(this.state.futureSessions && this.state.futureSessions.length > 0) && 
                  <div>
                    Streaming live each night! Complete the <a className="body-link" href="/events/wls-2021-reg">Livestream Registration</a> to be eligible to receive giveaways.
                  </div>
                }
                {(this.state.pastSessions && this.state.pastSessions.length > 0 && (!this.state.futureSessions || this.state.futureSessions.length === 0)) && 
                  <div>
                    These live broadcasts have ended. The rebroadcasts do not include the live concert 
                    from the guest artist, and the giveaway items have already been awarded to the live audience winners.
                  </div>
                }
              </Col>
            </Row>
            {((!this.state.pastSessions || this.state.pastSessions.length === 0) && (!this.state.futureSessions || this.state.futureSessions.length === 0)) && 
              <Row className="py-5 align-items-center text-center"><Col xs={12} md={12}><h3>COMING SOON!</h3></Col></Row> 
            }
            {(this.state.pastSessions && this.state.pastSessions.length > 0) && 
              <Row className="py-5 align-items-center text-center">
                {this.state.pastSessions.map((session) => {
                  return (
                    <Col key={"pastsession-" + session.name} xs={12} md={6} lg={4}>
                      <VimeoEmbed vimeoId={session.vimeoId} title={session.name} />
                      <h3 className="pt-1">{session.name}</h3>
                      { session.description && <div className="pb-3" dangerouslySetInnerHTML={{
                          __html: session.description.childMarkdownRemark.html,
                        }}
                      />}
                    </Col>
                  );
                })}
              </Row>
            }
            {(this.state.futureSessions && this.state.futureSessions.length > 0) && 
              <Row className="pt-5 pb-4 align-items-center text-center">
                {this.state.futureSessions.map((session) => {
                  return (
                    <Col key={"futuresession-" + session.name} xs={12} md={6} lg={4}>
                      <VimeoEmbed vimeoId={session.vimeoId} title={session.name} />
                      <h3 className="pt-1">{session.name}</h3>
                      { session.description && <div className="pb-3" dangerouslySetInnerHTML={{
                          __html: session.description.childMarkdownRemark.html,
                        }}
                      />}
                    </Col>
                  );
                })}
              </Row>
            }

            {this.state.isRegistered && <Row className="justify-content-center text-center">
              <Col xs={12} md={10} lg={8}>
                You are registered for the livestream as {this.state.firstName} {this.state.lastName}. <a href="./" className="body-link" onClick={this.clearRegistered}>Not {this.state.firstName}</a>?
              </Col>
            </Row>}

          </Container>
        </Section>
        <Section className="wls2021SpeakersSection dark">
          <Container>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline className="white" center={true}>Who Is Speaking</Headline>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <div className="picHolder">
                  <GatsbyImage image={getImage(this.props.data.speaker1)} alt="Ron Hutchcraft" />
                  <h5>Ron Hutchcraft</h5>
                  <h6>&nbsp;</h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <div className="picHolder">
                  <GatsbyImage image={getImage(this.props.data.speaker2)} alt="Weston Francis" />
                  <h5>Weston Francis</h5>
                  <h6>(Navajo)</h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <div className="picHolder">
                  <GatsbyImage image={getImage(this.props.data.speaker3)} alt="Bobby Dean" />
                  <h5>Bobby Dean</h5>
                  <h6>(Tonawanda Seneca)</h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <div className="picHolder">
                  <GatsbyImage image={getImage(this.props.data.speaker4)} alt="Nick Liew" />
                  <h5>Nick Liew</h5>
                  <h6>(Jicarilla Apache / Chinese)</h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <div className="picHolder">
                  <GatsbyImage image={getImage(this.props.data.speaker5)} alt="Dustin Terry" />
                  <h5>Dustin Terry</h5>
                  <h6>(Muskogee Creek / Cherokee)</h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <div className="picHolder">
                  <GatsbyImage image={getImage(this.props.data.speaker6)} alt="Craig Smith" />
                  <h5>Craig Smith</h5>
                  <h6>(Ojibwe)</h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <div className="picHolder">
                  <GatsbyImage image={getImage(this.props.data.speaker7)} alt="Seth & Sara Stevens" />
                  <h5>Seth & Sara Stevens</h5>
                  <h6>(Navajo)</h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <div className="picHolder">
                  <GatsbyImage image={getImage(this.props.data.speaker8)} alt="...and many more" />
                  <h5>&nbsp;</h5>
                  <h6>&nbsp;</h6>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="wls2021FAQSection dark">
          <Container>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline className="white" center={true}>What You Need To Know</Headline>
              </Col>
            </Row>
            <Row className="py-5 justify-content-center text-center">
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <h3>What is it?</h3>
                <p>Warrior Leadership Summit is a Native youth discipleship conference for people ages 15-30. It&apos;s designed to help you discover the power of doing life with Jesus, alongside people from nearly 100 different tribes, WLS is too big to miss.</p>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <h3>When is it?</h3>
                <p>
                  July 1 - July 6, 2021<br/>
                  Please plan to arrive between 10am - 3pm on Thursday, July 1. Registration opens at 10am. WLS will conclude after breakfast on July 6.
                </p>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <h3>What&apos;s Happening?</h3>
                <p>
                  Sports Tournaments, Swimming, Paddle Boats, Softball, Basketball (of course), Canoes, Inflatables on the lake, Obstacle Course, Blacklight Dodgeball, Archery and so much more!
                </p>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <h3>Where is it?</h3>
                <p>
                  WLS takes place at the Lake Williamson Retreat Center in Carlinville, IL. You&apos;re going to love it!
                </p>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <h3>How much is it?</h3>
                <p>
                  Prior to June 1st - $30<br/>
                  After June 1st, including at the door - $40.<br/>
                  Call us or mail your registration before June 1st to lock in the Early Registration price. The registration fee covers lodging, meals, and WLS-sponsored activities during the week. Transportation is not provided.
                </p>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <h3>Will WLS be in person?</h3>
                <p>
                  Our current plans are to host WLS on-site at Lake Williamson Retreat Center.  Our team is keeping a close eye on where things are at with the coronavirus, and will make sure to share any updated info on social media, on our website, and directly with people who have registered.
                </p>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <h3>Will there be safety protocols in place?</h3>
                <p>
                  We will have appropriate <a className="body-link" href="/wls-faq">safety protocols</a> in place.  We will communicate those protocols directly with people who are registered for WLS 2021, as well as share them in other appropriate forums.  We recommend early registration so you can stay up-to-date, and to secure your in-person spot before space fills up.
                </p>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-2 mt-4">
                <h3>What to expect?</h3>
                <p>
                  On top of fun activities, friends and food, the heart of WLS is the life-changing teaching and incredible worship. Our goal is to empower you to live the life only Jesus can give you.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <h1>HAVE MORE QUESTIONS?</h1>
              </Col>
              <Col xs={12} className="text-center mt-3"><Button href="/wls-faq" variant="primary" className="px-4 font-weight-bold">CLICK HERE</Button></Col>
            </Row>
          </Container>
        </Section>
        <Section className="wls2021LookBackSection dark" centerVertically={true}>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} lg={7}>
                <VimeoEmbed vimeoId="534026218" useCinema={true}></VimeoEmbed>
              </Col>
              <Col xs={12} lg={5}>
                <div className="d-none d-lg-block">
                  <Headline center={false} className="pt-3">A Look Back: WLS Highlights</Headline>
                </div>
                <div className="d-block d-lg-none">
                  <Headline center={true} className="pt-3">A Look Back: WLS Highlights</Headline>
                </div>
                <div className="text-center text-lg-left">
                  Just push play on this video to check out some of the incredible memories that are made at WLS!  It's an amazing week with unforgettable fun, great food, and life-changing moments...all alongside hundreds of other people like you!  Don't miss out on WLS 2021, July 1-6!
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="wls2021GuestSection primary py-0 my-0" useContainer={false}>
          <StaticImage placeholder="none" src="../../assets/img/events/wls-2021/Trip Lee.png" alt="Special Guest Trip Lee" style={{marginBottom: "-7px"}} />
        </Section>
        { (bestName == null) &&
        <Section className="wls2021Registration dark" useContainer={false}>
          <Row className="justify-content-center text-center">
            <Col xs={11} sm={10} md={10} lg={6}>
              <Headline  center={true} className="pt-3">Registration Info</Headline>
              <div className="text-left center-instructions pt-3">
                <p>
                While we do not have online registration, you can:
                </p>
                <p>
                1) Send an email to <a className="underline" href="mailto:wls@oneagleswings.com?subject=Requesting%20A%20WLS%20Registration%20Packet">wls@oneagleswings.com</a> to lock <br/>in the Early Bird rate or with other questions.
                </p>
                <p className="pl-4">
               - OR -
                </p>
                <p className="pb-0">
                2) Download the forms that need to be <br/>completed to be fully registered for WLS 2021.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col xs={12} className="text-center mt-4"><Button target="__blank" href="https://assets.ctfassets.net/v00hl7adu44i/79lWmmovRZ4yo9fcRu8vij/dd9355cb47bc26b53d7a5288ff12840b/WLS-2021-Registration-Forms.pdf" variant="primary" className="px-4 font-weight-bold">DOWNLOAD FORMS</Button></Col>
          </Row>
        </Section>
        }
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    ministryEvent: contentfulMinistryEvent(slug: { eq: "wls-2021" }) {
      ...MinistryEvent
      sessions {
        ...MinistryEventSession
      }
    }

    speaker1: file(relativePath: { eq: "events/wls-2021/Ron.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker2: file(relativePath: { eq: "events/wls-2021/Weston.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker3: file(relativePath: { eq: "events/wls-2021/Bobby.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker4: file(relativePath: { eq: "events/wls-2021/Nick.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker5: file(relativePath: { eq: "events/wls-2021/Dustin.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker6: file(relativePath: { eq: "events/wls-2021/Craig.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
    speaker7: file(relativePath: { eq: "events/wls-2021/Sarah-Seth.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
    speaker8: file(relativePath: { eq: "events/wls-2021/many-more.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
  }
`
